import * as React from 'react';

export class LinkNewWindowComponent extends React.Component<any, any> {
  render() {
    const { mark, children } = this.props;
    const isHashLink = mark.href?.startsWith('#');

    const target = isHashLink ? '_self' : '_blank';
    const relHash = isHashLink ? undefined : 'noreferrer';
    const rel = mark.nofollow ? 'nofollow' : relHash;

    return (
      <a
        href={mark.href}
        target={target}
        rel={rel}
        className='cursor-pointer text-indigo-500 underline hover:no-underline dark:text-blue-500'
      >
        {children}
      </a>
    );
  }
}
