import React, { Suspense, useState } from 'react';
import dynamic from 'adapter/next/dynamic';
import { Button, type ButtonProps, Link } from '@superside/ui';
import { type ButtonSizeType } from '@superside/ui/src/Controls/Button/types';
import { type CtaProps } from '../../types/ctaButton';

const ModalWithProgressiveForm = dynamic(
  () =>
    import(
      /* webpackchunkName: "ModalWithProgressiveForm" */ '../../components/ModalWithProgressiveForm'
    )
);

export const OpenCtaOptions = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
} as const;

export type OpenCtaOptionsType = (typeof OpenCtaOptions)[keyof typeof OpenCtaOptions];

export interface HeroCtaGroupProps {
  primaryCta?: CtaProps;
  secondaryCta?: CtaProps;
  disableSecondaryCtaMobile?: boolean;
}

export const HeroCtaGroup: React.FC<HeroCtaGroupProps> = ({
  primaryCta,
  secondaryCta,
  disableSecondaryCtaMobile
}) => {
  return (
    <div className='480:self-center 768:w-[initial] 768:flex-row 768:self-start 1280:self-center mt-8 flex w-full flex-col gap-2'>
      {primaryCta && (
        <ProgressiveModalEnabledCta
          cta={primaryCta}
          ctaOption={OpenCtaOptions.PRIMARY}
          variant={primaryCta.ctaVariant || 'primary'}
        />
      )}

      {secondaryCta && (
        <ProgressiveModalEnabledCta
          cta={secondaryCta}
          ctaOption={OpenCtaOptions.SECONDARY}
          variant={secondaryCta.ctaVariant || 'outline'}
          className={disableSecondaryCtaMobile ? '<480:hidden' : ''}
        />
      )}
    </div>
  );
};

export interface ProgressiveModalEnabledCtaProps {
  cta: CtaProps;
  ctaOption?: OpenCtaOptionsType;
  variant?: ButtonProps['variant'];
  ctaVariant?: ButtonProps['variant'];
  className?: string;
  size?: ButtonSizeType;
  icon?: React.ReactElement;
  handleClick?: () => void;
}

export const ProgressiveModalEnabledCta: React.FC<ProgressiveModalEnabledCtaProps> = ({
  cta,
  ctaOption = OpenCtaOptions.PRIMARY,
  variant = cta?.ctaVariant || 'primary',
  className,
  size,
  icon,
  handleClick
}) => {
  const [ctaOpen, setCtaOpen] = useState<OpenCtaOptionsType | null>(null);

  const isModalWithProgressiveForm = cta?.ctaType === 'modalWithProgressiveForm';

  const isModalOpen = ctaOpen === ctaOption && isModalWithProgressiveForm;

  const handleOpenCta = () => {
    handleClick && handleClick();
    setCtaOpen(ctaOption);
  };

  const handleCloseCta = () => {
    setCtaOpen(null);
  };

  if (!cta.ctaText) {
    return null;
  }

  const commonProps = {
    variant,
    label: cta.ctaText,
    className,
    size,
    icon
  };

  return (
    <>
      {isModalOpen ? (
        <Suspense>
          <ModalWithProgressiveForm
            heroBlockDetails={cta.predefinedForm}
            onClose={handleCloseCta}
          />
        </Suspense>
      ) : null}

      {isModalWithProgressiveForm ? (
        <Button {...commonProps} onClick={handleOpenCta} />
      ) : (
        <Link
          {...commonProps}
          href={cta?.ctaUrl || cta?.ctaLink || '#'}
          target={cta?.isTargetBlank ? '_blank' : '_self'}
          onClick={handleClick}
        />
      )}
    </>
  );
};
