import React, { forwardRef, useContext } from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';
import { DarkContext } from '../../contexts/DarkContext';
import { composeStyles } from '../../utils';
import {
  useAlignSelfStyle,
  useColorStyles,
  useFillStyle,
  useMarginStyles,
  useWidthStyles
} from '../../styles';
import { omitStyleProps } from '../Box';
import { useIsDark } from '../../providers';
import theme from '../../theme/theme';
import {
  defaultVariant,
  defaultDarkVariant,
  primaryVarinat,
  focusStyle,
  plainVariant,
  plainIconVariant,
  clickAreaStyle,
  loadingStyle,
  plainHrefVariant,
  largeSizeStyle,
  smallSizeStyle,
  xsmallSizeStyle,
  xxsmallSizeStyle,
  disabledVariant,
  boldStyle,
  secondaryVariant,
  primaryOutlinedVariant,
  wideStyle,
  whiteOutlinedVariant
} from './styles';
import { type ButtonProps } from './index';

const useStyles = composeStyles(
  useFillStyle,
  useMarginStyles,
  useAlignSelfStyle,
  useWidthStyles,
  useColorStyles
);

export const BaseButton: React.FC<ButtonProps> = forwardRef((props: any, ref) => {
  const {
    href,
    focusIndicator = true,
    primary,
    secondary,
    primaryOutlined,
    plain,
    plainIcon,
    clickArea,
    loading,
    large,
    small,
    xsmall,
    xxsmall,
    bold,
    wide,
    isTargetBlank = false,
    hasNoReferrer = false,
    whiteAligned,
    ...rest
  } = props;

  const themeContext = useContext(DarkContext);
  const { dark } = themeContext || {};
  const { style, className } = useStyles(props, theme);
  const isDark = useIsDark();

  const finalProps = omitStyleProps({
    ref,
    style: { ...style, ...rest.style },
    ...rest,
    className: cn(
      className,
      defaultVariant,
      dark && defaultDarkVariant,
      primary && primaryVarinat,
      secondary && secondaryVariant,
      primaryOutlined && primaryOutlinedVariant,
      focusIndicator && focusStyle,
      plain && plainVariant,
      plain && href && plainHrefVariant,
      plainIcon && plainIconVariant,
      clickArea && clickAreaStyle,
      loading && loadingStyle,
      large && largeSizeStyle,
      small && smallSizeStyle,
      xsmall && xsmallSizeStyle,
      xxsmall && xxsmallSizeStyle,
      rest.disabled && disabledVariant,
      bold && boldStyle,
      wide && wideStyle,
      isDark && 'dark',
      whiteAligned && whiteOutlinedVariant,
      rest.className
    )
  });

  if (isTargetBlank) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={href}
        target='_blank'
        rel={`noopener ${hasNoReferrer ? 'noreferrer' : ''}`}
        {...finalProps}
      />
    );
  }

  return href ? <Link href={href} {...finalProps} /> : <button {...finalProps} />;
});

BaseButton.displayName = 'BaseButton';
