import { cn } from '@superside/ui';

export const styling = (props: any) =>
  cn(
    typeof props.xxlg !== 'undefined' ? (props.xxlg ? styles.xxlFlex : styles.xxlNone) : '',
    typeof props.xlg !== 'undefined' ? (props.xlg ? styles.xlFlex : styles.xlNone) : '',
    typeof props.lg !== 'undefined' ? (props.lg ? styles.lgFlex : styles.lgNone) : '',
    typeof props.md !== 'undefined' ? (props.md ? styles.mdFlex : styles.mdNone) : '',
    typeof props.sm !== 'undefined' ? (props.sm ? styles.smFlex : styles.smNone) : '',
    typeof props.xsm !== 'undefined' ? (props.xs ? styles.xsFlex : styles.xsNone) : ''
  );

/**
 * FIXME: In production build media queries are sorted from smallest to largest (mobile to desktop).
 * When there are multiple selectors with equal specificity, the last matched will be used.
 *
 * It is fine in mobile-first approach:
 * - phone will use value declared without any media query
 * - tablet will take one of the media queries
 * - desktop will most likely take the last rule
 *
 * However, migrating from desktop-first to mobile-first approach caused a problem. Some components (BreakpointShowBox
 * and BreakpointBasisBox) rely on desktop-first API. To make them work the way they worked before I added "&" to each
 * size variant to increase its specificity (effectively, forcing desktop-first approach here).
 */
const styles = {
  xsFlex: 'min-[460px]:flex',
  xsNone: 'min-[460px]:hidden',
  smFlex: 'min-[576px]:flex',
  smNone: 'min-[576px]:hidden',
  mdFlex: 'min-[768px]:flex',
  mdNone: 'min-[768px]:hidden',
  lgFlex: 'min-[992px]:flex',
  lgNone: 'min-[992px]:hidden',
  xlFlex: 'min-[1200px]:flex',
  xlNone: 'min-[1200px]:hidden',
  xxlFlex: 'flex',
  xxlNone: 'hidden'
};

const propToClassNames: any = {
  xxlg: [styles.xxlFlex, styles.xxlNone],
  xlg: [styles.xlFlex, styles.xlNone],
  lg: [styles.lgFlex, styles.lgNone],
  md: [styles.mdFlex, styles.mdNone],
  sm: [styles.smFlex, styles.smNone],
  xsm: [styles.xsFlex, styles.xsNone]
};

const allowedProps = ['xxlg', 'xlg', 'lg', 'md', 'sm', 'xsm'];

export const useBreakpointShowStyle = (props: any) => {
  const className = allowedProps.reduce((acc: any, breakpointProp: any) => {
    const value = props[breakpointProp];

    if (typeof value === 'undefined') {
      return acc;
    }

    const [visibleClassName, hiddenClassName] = propToClassNames[breakpointProp];

    return [...acc, value ? visibleClassName : hiddenClassName];
  }, []);

  return {
    className
  };
};
