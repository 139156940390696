import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { cva } from 'class-variance-authority';
import { Box, type Theme, Themes, cn } from '@superside/ui';
import { useAtomValue } from '@konsus/atoms';
import { darkModesByTheme } from '@konsus/apps-common/src/utils/useTheme';
import { lightToDarkSwitchAtom, pageVibeDarkMode } from '../utils';

export type PaddingSize =
  | 'xxxlarge'
  | 'none'
  | 'hair'
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

const pageSectionCva = cva(['1280:mx-auto relative w-full transition-colors'], {
  variants: {
    isLight: {
      true: 'light 1024:max-w-[calc(1920px_-_64px)] 1024:w-[calc(100%_-_64px)] flex w-full max-w-full flex-col self-center rounded-lg bg-[#fff]',
      false: ''
    },
    dark: {
      true: 'dark'
    },
    unlimited: {
      true: '1280:max-w-none px-0',
      false: '768:px-8 1280:max-w-aurora px-4'
    },
    unlimitedMobile: {
      true: '<768:px-0 1280:max-w-aurora'
    },
    unlimitedTablet: {
      true: '<1280:px-0 1280:max-w-aurora'
    },
    unlimitedDesktop: {
      true: '1280:px-0'
    },
    paddingTop: {
      none: 'pt-0',
      hair: 'pt-[1px]',
      xxsmall: 'pt-1',
      xsmall: 'pt-2',
      small: 'pt-4',
      medium: 'pt-6',
      large: '768:pt-8 pt-6',
      xlarge: '768:pt-12 pt-8',
      xxlarge: '768:pt-16 pt-12',
      xxxlarge: '768:pt-22 pt-16',
      6: 'pt-6',
      7: 'pt-7',
      8: 'pt-8',
      9: '768:pt-15 pt-12',
      10: 'pt-15 768:pt-20',
      11: 'pt-23 768:pt-30',
      12: 'pt-34 768:pt-45'
    },
    paddingBottom: {
      none: 'pb-0',
      hair: 'pb-[1px]',
      xxsmall: 'pb-1',
      xsmall: 'pb-2',
      small: 'pb-4',
      medium: 'pb-6',
      large: '768:pb-8 pb-6',
      xlarge: '768:pb-12 pb-8',
      xxlarge: '768:pb-16 pb-12',
      xxxlarge: '768:pb-22 pb-16',
      6: 'pb-6',
      7: 'pb-7',
      8: 'pb-8',
      9: '768:pb-15 pb-12',
      10: 'pb-15 768:pb-20',
      11: 'pb-23 768:pb-30',
      12: 'pb-34 768:pb-45'
    }
  },
  defaultVariants: {
    paddingBottom: 'xxxlarge',
    paddingTop: 'xxxlarge',
    isLight: false,
    dark: true,
    unlimited: false,
    unlimitedMobile: false,
    unlimitedDesktop: false
  }
});

export interface PageSectionProps {
  isLight?: boolean;
  className?: string;
  contentClassName?: string;
  style?: { [key: string]: unknown };
  as?: 'div' | 'section' | 'button' | 'a' | 'nav' | 'ul';
  hashId?: string;
  unlimited?: boolean;
  unlimitedMobile?: boolean;
  unlimitedTablet?: boolean;
  unlimitedDesktop?: boolean;
  paddingTop?: PaddingSize;
  paddingBottom?: PaddingSize;
}

export const PageSection: React.FC<PropsWithChildren<PageSectionProps>> = ({
  as: RenderTag = 'section',
  isLight,
  unlimited,
  style,
  hashId,
  children,
  className,
  contentClassName,
  unlimitedDesktop,
  ...rest
}) => {
  const isLightToDarkTransition = useAtomValue(lightToDarkSwitchAtom);
  const darkMode = useAtomValue(pageVibeDarkMode);
  const [dataTheme, setDataTheme] = useState<Theme>(Themes.aurora);

  useEffect(() => {
    const bodyDataTheme = document.body.dataset.theme as Theme;

    if (bodyDataTheme) {
      setDataTheme(bodyDataTheme);
    }
  }, []);

  const dark =
    isLight !== undefined
      ? !isLight
      : isLightToDarkTransition !== null
      ? !isLightToDarkTransition
      : darkMode ?? darkModesByTheme[dataTheme];

  return (
    <RenderTag
      className={cn(
        pageSectionCva({
          className,
          isLight,
          unlimited,
          dark,
          unlimitedDesktop,
          ...rest
        })
      )}
      style={style}
      data-testid='pageSection'
    >
      {hashId && <div className='apply-without-header absolute left-0 top-0 ' id={hashId} />}
      <Box
        className={cn(
          isLight && (!unlimited || !unlimitedDesktop)
            ? '!max-w-aurora 1440:px-8 mx-auto w-full'
            : 'dark:text-light',
          contentClassName
        )}
      >
        {children}
      </Box>
    </RenderTag>
  );
};
