import React, { forwardRef } from 'react';
import { omit } from 'ramda';
import { cn } from '@superside/ui';
import { composeStyles } from '../../utils';
import theme from '../../theme/theme';
import {
  useMarginStyles,
  useTextSizeStyles,
  useClickAreaStyles,
  useColorStyles,
  type Spacing
} from '../../styles';

export interface AnchorProps {
  id?: string | number;
  target?: '_self' | '_blank' | '_parent' | '_top';
  clickArea?: boolean;
  href?: string;
  ref?;
  rel?;
  title?;
  onClick?;
  size?: string;
  label?: string;
  className?: string;
  color?: string;
  margin?: Spacing;
  children?: React.ReactElement;
}

const filterProps = omit(['size', 'margin']);

const useStyles = composeStyles(
  useMarginStyles,
  useTextSizeStyles,
  useClickAreaStyles,
  useColorStyles
);

export const Anchor: React.FC<AnchorProps> = forwardRef((componentProps, ref) => {
  const { label, children } = componentProps;
  const { style, className } = useStyles(componentProps, theme);
  const filteredProps = filterProps(componentProps);

  const finalProps = {
    ...filteredProps,
    style: {
      ...style,
      ...filteredProps.style
    },
    className: cn(
      `cursor-pointer break-words font-normal text-indigo-500 no-underline shadow-none hover:font-medium hover:underline`,
      className,
      componentProps.className
    )
  };

  return (
    <a ref={ref} {...finalProps}>
      {label || children}
    </a>
  );
});

Anchor.displayName = 'Anchor';

export default Anchor;
