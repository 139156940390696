import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '@superside/ui';
import { Box, Button } from '@konsus/superside-kit';
import styles from './CardGrid.module.css';

interface CardGridProps {
  lastRowFillItem?: JSX.Element | null;
  isCollapsed?: boolean;
  is3Columns?: boolean;
  show3ElementsMobile?: boolean;
  itemsLength: number;
  hasBigRowGap?: boolean;
  className?: string;
  style?: { [x: string]: any };
  columnSize?: 3 | 4 | 5 | 6;
  children?: React.ReactNode;
}

export const CardGrid: React.FunctionComponent<CardGridProps> = (props) => {
  const {
    lastRowFillItem,
    is3Columns = true,
    show3ElementsMobile = false,
    isCollapsed = false,
    hasBigRowGap = false,
    children,
    className,
    style,
    itemsLength,
    columnSize,
    ...rest
  } = props;

  const [showMore, setShowMore] = useState(!isCollapsed);

  useEffect(() => setShowMore(!isCollapsed), [isCollapsed]);

  const gridColsSize = columnSize || (is3Columns ? 3 : 4);

  const lastBlockStyles = useMemo(
    () => ({
      '--threeColSpan': gridColsSize - (itemsLength % gridColsSize),
      '--twoColSpan': 2 - (itemsLength % 2)
    }),
    [gridColsSize, itemsLength]
  );

  const tilesWrapperStyle = useMemo(
    () => ({
      '--gridCols': gridColsSize,
      ...style
    }),

    [gridColsSize, style]
  );

  return (
    <div
      {...rest}
      style={tilesWrapperStyle as any}
      className={cn(
        styles.tilesWrapper,
        showMore && styles.showAllTiles,
        show3ElementsMobile ? (lastRowFillItem ? styles.mobileOnlyLast : styles.mobileHideAll) : '',
        hasBigRowGap && '1024:gap-y-12 1920:gap-y-16',
        'gap-x-6 gap-y-8',
        className
      )}
    >
      {children}
      {!showMore ? (
        <Box
          justify='center'
          align='center'
          className={cn(styles.buttonWrapper, '768:hidden rounded-lg§ mb-6 w-full')}
        >
          <Button primary onClick={() => setShowMore(!showMore)}>
            See more
          </Button>
        </Box>
      ) : null}
      {lastRowFillItem ? (
        <Box
          as='article'
          style={lastBlockStyles as any}
          className={
            '1024:col-[span_var(--threeColSpan)] 768:col-[span_var(--twoColSpan)] relative'
          }
        >
          {lastRowFillItem}
        </Box>
      ) : null}
    </div>
  );
};

export default CardGrid;
