import React from 'react';
import { cn } from '@superside/ui';
import { Box, type BoxProps } from '../Box';
import styles from './index.module.css';

export const newExternalContentStyle = styles.newExternalContentStyle;
export const externalContentStyle = styles.externalContentStyle;
export const contentWrapperStyle = styles.contentWrapperStyle;

export const ExternalContentWrapper: React.FC<BoxProps> = ({ className, ...rest }) => (
  <Box {...rest} className={cn(externalContentStyle, className)} />
);

export default ExternalContentWrapper;
