import styles from './styles.module.css';
export const defaultVariant = styles.defaultVariant;
export const defaultDarkVariant = styles.defaultDarkVariant;
export const primaryVarinat = styles.primaryVarinat;
export const secondaryVariant = styles.secondaryVariant;
export const primaryOutlinedVariant = styles.primaryOutlinedVariant;
export const whiteOutlinedVariant = styles.whiteOutlinedVariant;
export const disabledVariant = styles.disabledVariant;
export const boldStyle = styles.boldStyle;
export const wideStyle = styles.wideStyle;
export const plainVariant = styles.plainVariant;
export const plainHrefVariant = styles.plainHrefVariant;
export const plainIconVariant = styles.plainIconVariant;
export const clickAreaStyle = styles.clickAreaStyle;
export const loadingStyle = styles.loadingStyle;
export const focusStyle = styles.focusStyle;
export const largeSizeStyle = styles.largeSizeStyle;
export const smallSizeStyle = styles.smallSizeStyle;
export const xsmallSizeStyle = styles.xsmallSizeStyle;
export const xxsmallSizeStyle = styles.xxsmallSizeStyle;
