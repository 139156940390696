import React from 'react';
import {
  Box,
  type BoxProps,
  Button,
  RelativeBox,
  Text,
  ExternalContentWrapper,
  contentWrapperStyle
} from '@konsus/superside-kit';
import NextImage from '@konsus/image';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { Heading } from '@superside/ui';
import VisualAsset, { type VisualAssetProps } from '../../components/VisualAsset';
import styles from './KeyFeaturesItem.module.css';

export interface KeyFeaturesItemProps extends BoxProps {
  descriptionText: string;
  descriptionRichText: { content: Array<any> };
  image?: any;
  asset?: VisualAssetProps;
  title?: string;
  url?: string;
  _key: string;
}

export const KeyFeaturesItem: React.FunctionComponent<KeyFeaturesItemProps> = (props) => {
  const { descriptionText, descriptionRichText, url, image, asset, title, ...rest } = props;

  return (
    <RelativeBox pad={{ bottom: '8' }} margin={{ bottom: '9' }} {...rest}>
      {asset || image ? (
        <Box
          className={'mb-[var(--edge-4)] overflow-hidden rounded-lg [&_div]:h-full [&_div]:w-full'}
        >
          {asset ? (
            <VisualAsset {...asset} alt={title || descriptionText} />
          ) : (
            <NextImage image={image} alt={title || descriptionText} requestWidth={600} />
          )}
        </Box>
      ) : null}
      {title ? (
        <Heading level='h6' as='h4'>
          {title}
        </Heading>
      ) : null}
      {descriptionRichText ? (
        <ExternalContentWrapper margin={{ vertical: '2' }} className={contentWrapperStyle}>
          <SanityBlockComponent blocks={descriptionRichText} />
        </ExternalContentWrapper>
      ) : descriptionText ? (
        <Text
          className={`${styles.descriptionTextStyle} overflow-hidden text-ellipsis`}
          margin={{ vertical: '2' }}
        >
          {descriptionText}
        </Text>
      ) : null}
      {url ? <Button plain clickArea href={url} /> : null}
    </RelativeBox>
  );
};
