import React, { forwardRef } from 'react';
import { Spinner } from 'phosphor-react';
import { Box } from '../Box';
import { type EdgeSize } from '../../theme/edge-size';
import { type Spacing, type AlignSelf } from '../../styles';
import { BaseButton } from './BaseButton';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  large?: boolean;
  small?: boolean;
  xsmall?: boolean;
  xxsmall?: boolean;
  destructive?: boolean;
  alternative?: boolean;
  neutral?: boolean;
  filled?: boolean;
  clickArea?: boolean;
  download?: boolean;
  loading?: boolean;
  title?: string;
  style?: any;
  label?: any;
  bold?: boolean;
  removeMargins?: boolean;
  width?: string | number;
  xlgWidth?: string | number;
  lgWidth?: string | number;
  mdWidth?: string | number;
  smWidth?: string | number;
  xsmWidth?: string | number;
  fill?: any;
  href?: string;
  sx?: any;
  icon?: any;
  align?: string;
  gap?: EdgeSize;
  plain?: boolean;
  primary?: boolean;
  primaryOutlined?: boolean;
  whiteAligned?: boolean;
  secondary?: boolean;
  reverse?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
  ref?: any;
  plainIcon?: boolean;
  focusIndicator?: boolean;
  wide?: boolean;
  isTargetBlank?: boolean;
  hasNoReferrer?: boolean;
  alignSelf?: AlignSelf;
  margin?: Spacing;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = forwardRef((componentProps, ref) => {
  const {
    children,
    label,
    icon,
    align = 'center',
    gap = 'small',
    reverse = false,
    ...rest
  } = componentProps;

  let contests;
  const content = label || children;

  if (content && icon) {
    contests = (
      <Box
        direction={reverse ? 'row-reverse' : 'row'}
        align='center'
        justify={align === 'center' ? 'center' : 'between'}
        gap={gap}
        responsive={false}
      >
        {content}
        {icon}
      </Box>
    );
  } else {
    contests = content || icon;
  }

  return (
    <BaseButton ref={ref} plainIcon={!!(!content && icon)} {...rest}>
      {rest.loading ? <Spinner className='animate-spin' /> : contests}
    </BaseButton>
  );
});

Button.displayName = 'Button';

export default Button;
